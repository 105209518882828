import * as React from 'react'

const Sun = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 162.227 162.227'
      height='40pt'
      width='40pt'
      {...props}
    >
      <path
        d='M81.113 48.265v65.697c18.142 0 32.848-14.707 32.848-32.849 0-18.141-14.706-32.848-32.848-32.848z'
        fill='#fdd633'
      />
      <path
        d='M48.266 81.113c0 18.142 14.706 32.849 32.848 32.849V48.265c-18.142 0-32.848 14.707-32.848 32.848zM43.97 8.896L65.93 46.93l-6.825 3.94-21.96-38.035zM12.836 37.143l38.035 21.96-3.94 6.824-38.035-21.96zM0 77.173h43.921v7.881H0zM8.897 118.253l38.036-21.96 3.94 6.824-38.036 21.96zM37.14 149.39l21.96-38.035 6.825 3.94-21.96 38.035z'
        fill='#f4ca19'
      />
      <path
        fill='#fdd633'
        d='M118.253 153.332l-21.96-38.036 6.824-3.94 21.961 38.036zM149.392 125.085l-38.035-21.96 3.94-6.825 38.035 21.96zM118.304 77.174h43.922v7.88h-43.922zM153.333 43.972l-38.036 21.96-3.94-6.824 38.035-21.96zM125.083 12.836l-21.961 38.036-6.824-3.94 21.96-38.036z'
      />
      <path
        fill='#f4ca19'
        d='M69.606 22.954l5.684 21.212-7.61 2.04-5.684-21.213zM42.074 36.5l15.528 15.527L52.03 57.6 36.502 42.071zM22.957 69.607l2.038-7.612 21.213 5.68-2.038 7.611zM24.994 100.226l-2.04-7.612 21.211-5.685 2.04 7.612zM52.036 104.625l5.572 5.573-15.528 15.529-5.573-5.573zM69.614 139.268l-7.612-2.04 5.686-21.212 7.612 2.04z'
      />
      <path
        fill='#fdd633'
        d='M92.614 139.272L86.93 118.06l7.613-2.04 5.684 21.212zM120.152 125.727l-15.528-15.529 5.572-5.573 15.529 15.53zM139.267 92.61l-2.04 7.613-21.213-5.686 2.04-7.612zM137.235 61.997l2.04 7.612-21.212 5.685-2.04-7.612zM110.196 57.603l-5.574-5.572 15.526-15.53 5.573 5.572zM92.616 22.953l7.613 2.04-5.684 21.212-7.613-2.04zM81.112 0h3.94v43.92h-3.94z'
      />
      <path fill='#f4ca19' d='M77.174 0h3.938v43.92h-3.938z' />
      <path fill='#fdd633' d='M81.113 118.305h3.939v43.921h-3.939z' />
      <path
        fill='#f4ca19'
        d='M81.113 118.305l-3.941-.001v43.922h3.941v-43.921z'
      />
    </svg>
  )
}

export default Sun
