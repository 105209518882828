export interface CategoryType {
  name: string
  id: string
  typeId: string
  query?: string
}

const categories: CategoryType[] = [
  {
    name: 'phpBB Forum',
    id: 'forum-0',
    typeId: '0',
    query: '"powered by phpbb"',
  },
  {
    name: 'vBulletin Forum',
    id: 'forum-1',
    typeId: '0',
    query: '"powered by vBulletin"',
  },
  {
    name: 'MyBB Forum',
    id: 'forum-2',
    typeId: '0',
    query: '"Powered By MyBB"',
  },
  {
    name: 'Vanilla Forum',
    id: 'forum-3',
    typeId: '0',
    query: '"powered by Vanilla"',
  },
  {
    name: 'Expression Engine',
    id: 'forum-4',
    typeId: '0',
    query: '"Powered By ExpressionEngine"',
  },
  {
    name: 'SMF Forum',
    id: 'forum-5',
    typeId: '0',
    query: '"powered by SMF"',
  },
  {
    name: 'YaBB Forum',
    id: 'forum-6',
    typeId: '0',
    query: '"powered by YaBB"',
  },
  {
    name: 'Wordpress Blog',
    id: 'blog-0',
    typeId: '1',
    query: '"powered by wordpress"',
  },
  {
    name: 'Blogpost Comment',
    id: 'blog-1',
    typeId: '1',
    query: 'site:.blogspot.com',
  },
  {
    name: 'Drupal',
    id: 'blog-2',
    typeId: '1',
    query: '"Powered by Drupal"',
  },
  {
    name: 'BlogEngine.net',
    id: 'blog-3',
    typeId: '1',
    query: '"Powered by BlogEngine.NET"',
  },
  {
    name: 'Movable Type',
    id: 'blog-4',
    typeId: '1',
    query: '"Powered by Movable Type"',
  },
  {
    name: 'Mephist Blog',
    id: 'blog-5',
    typeId: '1',
    query: '"powered by Mephisto"',
  },
  {
    name: 's9y Blog',
    id: 'blog-6',
    typeId: '1',
    query: '"Powered by s9y"',
  },
  {
    name: 'Subtext Blog',
    id: 'blog-7',
    typeId: '1',
    query: '"Powered by subtext"',
  },
  {
    name: 'Serendipity Blog',
    id: 'blog-8',
    typeId: '1',
    query: '"powered by Serendipity"',
  },
  {
    name: 'LifeType Blog',
    id: 'blog-9',
    typeId: '1',
    query: '"Powered by LifeType"',
  },
  {
    name: 'Dotclear Blog',
    id: 'blog-10',
    typeId: '1',
    query: '"powered by dotclear"',
  },
  {
    name: 'PHPLD Directory',
    id: 'dir-0',
    typeId: '2',
    query: '"Powered by: phpLD"',
  },
  {
    name: 'Link Exchange',
    id: 'dir-1',
    typeId: '2',
    query: '',
  },
  {
    name: 'PHP Link Directory',
    id: 'dir-2',
    typeId: '2',
    query: '"Powered by: php Link Directory"',
  },
  {
    name: 'eSyndiCat Directory',
    id: 'dir-3',
    typeId: '2',
    query: '"Powered by eSyndiCat Directory Software"',
  },
  {
    name: 'Free Directory',
    id: 'dir-4',
    typeId: '2',
    query: '',
  },
  {
    name: 'All Directory',
    id: 'art-0',
    typeId: '3',
    query: 'intext:"submit"',
  },
  {
    name: 'PHPLD Article',
    id: 'art-1',
    typeId: '3',
    query: '"Designer: Free PHPLD Templates"',
  },
  {
    name: 'Article MS',
    id: 'art-2',
    typeId: '3',
    query: '"powered by articlems"',
  },
  {
    name: 'PHP Link Directory',
    id: 'art-3',
    typeId: '3',
    query: '"php Link Directory"',
  },
  {
    name: 'vBulletin Blog',
    id: 'art-4',
    typeId: '3',
    query: '"powered by vbulletin"',
  },
  {
    name: 'Article Dashboard',
    id: 'art-5',
    typeId: '3',
    query: '"Powered by Article Dashboard"',
  },
  {
    name: 'Pligg Site',
    id: 'sclb-0',
    typeId: '4',
    query: '"Pligg"',
  },
  {
    name: 'PHPDug',
    id: 'sclb-1',
    typeId: '4',
    query: ' "PHPDug"',
  },
  {
    name: 'Scuttle Site',
    id: 'sclb-2',
    typeId: '4',
    query: '',
  },
  {
    name: 'Scoops Site',
    id: 'sclb-3',
    typeId: '4',
    query: '"Published scoops"',
  },
  {
    name: 'Others All',
    id: 'sclb-5',
    typeId: '4',
    query: 'inurl:"story.php?title="',
  },
  {
    name: 'Become Contributor',
    id: 'guest-0',
    typeId: '5',
    query: '"Contribute to this site"',
  },
  {
    name: 'Guest Author Search',
    id: 'guest-1',
    typeId: '5',
    query: '',
  },
  {
    name: 'Want to Write',
    id: 'guest-2',
    typeId: '5',
    query: '"want to write for"',
  },
  {
    name: 'Author Wanted',
    id: 'guest-3',
    typeId: '5',
    query: '"looking for guest posts"',
  },
  {
    name: 'Posting Guidelines',
    id: 'guest-4',
    typeId: '5',
    query: '"guest posting guidelines"',
  },
  {
    name: 'Submit Blog Post',
    id: 'guest-5',
    typeId: '5',
    query: '"become a guest blogger"',
  },
  {
    name: 'Blogging Category',
    id: 'guest-6',
    typeId: '5',
    query: 'inurl:category/guest',
  },
  {
    name: 'My Guest Blog',
    id: 'guest-7',
    typeId: '5',
    query: '"my posts on other blogs"',
  },
  {
    name: "Angelae's Backlinks",
    id: 'bonus-0',
    typeId: '6',
    query: '',
  },
  {
    name: 'Squidoo Lens',
    id: 'bonus-1',
    typeId: '6',
    query: 'site:squidoo.com',
  },
  {
    name: 'Intense Debate Blog',
    id: 'bonus-2',
    typeId: '6',
    query: '',
  },
  {
    name: 'CommentLuv',
    id: 'bonus-3',
    typeId: '6',
    query: '"This blog uses premium CommentLuv"',
  },
  {
    name: 'Disqus Blog',
    id: 'bonus-4',
    typeId: '6',
    query: '"Powered by Disqus"',
  },
  {
    name: 'KeywordLuv Blog',
    id: 'bonus-5',
    typeId: '6',
    query: '',
  },
  {
    name: 'Livefyre Blog',
    id: 'bonus-6',
    typeId: '6',
    query: '"get livefyre"',
  },
  {
    name: 'Blog Commenting',
    id: 'edu-0',
    typeId: '7',
    query: 'site:.edu',
  },
  {
    name: 'Forum Posting',
    id: 'edu-1',
    typeId: '7',
    query: 'site:.edu',
  },
  {
    name: 'Scholarship Page',
    id: 'edu-2',
    typeId: '7',
    query: 'site:.edu',
  },
  {
    name: 'Resources Page',
    id: 'edu-3',
    typeId: '7',
    query: 'site:.edu',
  },
  {
    name: 'Contributor Site',
    id: 'edu-4',
    typeId: '7',
    query: 'site:.edu',
  },
  {
    name: 'Forum Posting',
    id: 'gov-0',
    typeId: '8',
    query: 'site:.gov',
  },
  {
    name: 'Blog Commenting',
    id: 'gov-1',
    typeId: '8',
    query: 'site:.gov',
  },
  {
    name: 'Resource Page',
    id: 'gov-2',
    typeId: '8',
    query: 'site:.gov',
  },
  {
    name: 'Find Contributor',
    id: 'gov-3',
    typeId: '8',
    query: 'site:.gov',
  },
]

export default categories
